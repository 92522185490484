function initAndOpenHelpcenterWidget() {
    FreshworksWidget('boot');
    FreshworksWidget('open');

    // Para detectar siempre que se cierre el widget, desde donde sea, lo destruimos
    // (y así nos aseguramos que el botón del widget siempre desaparece)
    setTimeout(() => {
        createWidgetClosedListener();
    }, 0);
}

function closeHelpcenterWidget() {
    FreshworksWidget('close');
}

// Utilizo la API MutationObserver: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
function createWidgetClosedListener() {
    // Select the node that will be observed for mutations
    const targetNode = document.querySelector("#freshworks-frame-wrapper").parentNode;

    // Create an observer instance with a callback function
    const observer = new MutationObserver((mutationList, observer) => {
        FreshworksWidget('destroy');
        observer.disconnect();
    });

    // Start observing the target node for configured mutations
    observer.observe(targetNode, { childList: true, subtree: false });
}
